import axios from "./axios";

class AuthClient {
    static SERVER_URL = process.env.REACT_APP_API_URL;

    static async signInGoogle(code: string, scope: string) {
        return await axios.get(AuthClient.SERVER_URL + "/auth/google", {
            params: {
                code: code,
                scope: scope
            },
        });
    }

    static async getMyProfile() {
        return await axios.get(AuthClient.SERVER_URL + "/users/me")
    }

    static async logout() {
        return await axios.post(AuthClient.SERVER_URL + "/auth/logout")
    }
}

export default AuthClient;