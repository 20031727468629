// @ts-nocheck
import {Avatar, Box, Typography} from "@mui/material";
import {useLoaderData} from "react-router-dom";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Logo from "./common/Logo";

const AuthSuccessPage = () => {
    const profileData = useLoaderData();

    return (
        <Box
            display="flex"
            justifyContent="center"
            minHeight="100vh"
            sx={{
                backgroundColor: "#eee"
            }}
        >
            <Box
                paddingTop="70px"
            >
                <Box display="flex" justifyContent="center" marginBottom="30px">
                    <Logo size="large" />
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    paddingX="200px"
                    paddingY="60px"
                    sx={{
                        backgroundColor: "white",
                        borderRadius: "8px"
                    }}
                    textAlign="center"
                >
                    <Typography
                        fontWeight="bold"
                        variant="h6"
                        fontSize="26px"
                        marginBottom="20px"
                    >You're signed in as</Typography>
                    <Avatar
                        sx={{
                            width: "70px",
                            height: "70px"
                        }}
                        src={profileData.data.profile_image_url}
                        alt={profileData.data.first_name}
                    />
                    <Typography fontWeight="bold" marginY="10px">
                        {profileData.data.first_name + " " + profileData.data.last_name}
                    </Typography>
                    <Box display="flex">
                        <CheckCircleOutlineIcon color="success" />
                        <Typography
                            fontWeight="bold"
                            variant="h6"
                            fontSize="14px"
                            lineHeight={1.8}
                            marginLeft="4px"
                        >
                            You may now close this window
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default AuthSuccessPage;