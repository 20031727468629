import {Box, Link, Typography } from "@mui/material";
import {CodeResponse, GoogleOAuthProvider} from "@react-oauth/google";
import GoogleAuthButton from "./common/GoogleAuthButton";
import {useNavigate} from "react-router-dom";
import AuthClient from "./auth_client";
import Logo from "./common/Logo";

const SignUpPage = () => {
    const navigate = useNavigate();
    // const login = useGoogleLogin((tokenResponse) => {
    //     console.log(tokenResponse)
    // })

    function handleGoogleCallback(tokenResponse: CodeResponse) {
        AuthClient.signInGoogle(tokenResponse.code, tokenResponse.scope).then((response) => {
            if (response.status === 200) {
                navigate("/auth/success");
            }
        });
    }

    return (
        <Box
            display="flex"
            minHeight="100vh"
            width="100%"
            sx={{
                backgroundColor: "#faf6eb"
            }}
            justifyContent="center"
        >
            <Box
                paddingTop="60px"
            >
                <Box display="flex" justifyContent="center" marginBottom="30px">
                    <Logo size="large" />
                </Box>
                <Box>
                    <Box
                        display="flex"
                        paddingX="40px"
                        paddingY="50px"
                        borderRadius="5px"
                        sx={{
                            backgroundColor: "white"
                        }}
                        flexDirection="column"
                        width="350px"
                    >
                        <Box marginBottom="30px">
                            <Typography
                                fontWeight="bold"
                                variant="h6"
                                marginBottom="5px"
                                fontSize="24px"
                            >
                                Get started with MyBin
                            </Typography>
                            <Typography color="text.secondary">
                                Just a few clicks away from saving many future clicks. For free.
                            </Typography>
                        </Box>
                        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
                            <GoogleAuthButton referrer="sign-up" />
                        </GoogleOAuthProvider>
                        {/*<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>*/}
                        {/*    <GoogleLogin*/}
                        {/*        size="large"*/}
                        {/*        width="270px"*/}
                        {/*        onSuccess={r => handleGoogleCallback(r)} />*/}
                        {/*</GoogleOAuthProvider>*/}
                    </Box>
                    <Box marginTop="20px" textAlign="center">
                        <Typography
                            fontSize="12px"
                            display="inline"
                            fontWeight="bold"
                        >
                            Already have an account?
                        </Typography>
                        <Link
                            marginLeft="3px"
                            display="inline"
                            href="/sign-in"
                            fontSize="12px"
                            color="#000000"
                        >
                            <Typography
                                fontSize="12px"
                                display="inline"
                                fontWeight="bold"
                            >Sign in</Typography></Link>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default SignUpPage;