import {Box, Link, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import AuthClient from "./auth_client";
import {CodeResponse, GoogleOAuthProvider} from "@react-oauth/google";
import GoogleAuthButton from "./common/GoogleAuthButton";
import Logo from "./common/Logo";

const SignIn = () => {
    const navigate = useNavigate();

    function handleGoogleCallback(googleResponse: CodeResponse) {
        AuthClient.signInGoogle(googleResponse.code, googleResponse.scope);
    }

    return (
        <Box>
            <Box display="flex" justifyContent="center" marginBottom="5px">
                <Logo size="large" />
            </Box>
            <Box textAlign="center">
                <Typography
                    fontWeight="bold"
                    variant="h6"
                    marginBottom="25px"
                    fontSize="18px"
                >
                    Welcome back!
                </Typography>
            </Box>
            <Box
                display="flex"
                paddingX="40px"
                paddingY="50px"
                borderRadius="10px"
                sx={{
                    backgroundColor: "white"
                }}
                flexDirection="column"
                width="350px"
            >
                <Box>
                    <Typography
                        fontWeight="bold"
                        variant="h6"
                        marginBottom="25px"
                        fontSize="24px"
                    >
                        Sign in to MyBin
                    </Typography>
                </Box>
                <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
                    <GoogleAuthButton referrer="sign-in" />
                    {/*<GoogleLogin*/}
                    {/*    size="large"*/}
                    {/*    width="270px"*/}
                    {/*    onSuccess={r => handleGoogleCallback(r)} />*/}
                </GoogleOAuthProvider>
            </Box>
            <Box marginTop="20px" textAlign="center">
                <Typography
                    fontSize="12px"
                    display="inline"
                    fontWeight="bold"
                >
                    Don't have an account?
                </Typography>
                <Link
                    marginLeft="3px"
                    display="inline"
                    href="/sign-up"
                    fontSize="12px"
                    color="#000000"
                >
                    <Typography
                        fontSize="12px"
                        display="inline"
                        fontWeight="bold"
                    >Sign up</Typography></Link>
            </Box>
        </Box>
    )
}

const SignInPage = () => {
    return (
        <Box
            display="flex"
            minHeight="100vh"
            width="100%"
            sx={{
                backgroundColor: "#faf6eb"
            }}
            justifyContent="center"
        >
            <Box
                paddingTop="80px"
            >
                <SignIn />
            </Box>
        </Box>
    )
}

export default SignInPage;