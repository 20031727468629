import {createTheme} from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            main: "#ffca4a",  // Mimosa
            contrastText: "#FFF"  // Charcoal
        },
        text: {
            secondary: "#3D4852"
        }
        // primary: "#FFA500",  // Bright Orange
        // primary: "#FFAE42",  // Yellow Orange
    }
})

export default theme;