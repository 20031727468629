// @ts-nocheck
import {Box, Button, Container, Paper, ThemeProvider, Typography} from "@mui/material";
import theme from "./theme";
import {useNavigate} from "react-router-dom";
import styled from "@emotion/styled";
import Typewriter from 'typewriter-effect';
import ChromeIcon from "./common/ChromeIcon";
import Logo from "./common/Logo";

const StyledTypography = styled(Typography)`
  strong {
    color: ${({theme}) => theme.palette.primary.main};
  }
`;

const LandingPage = () => {
    const navigate = useNavigate();
    return (
        <ThemeProvider theme={theme}>
            <Box>
                {/*<Box marginY="20px">*/}
                {/*    <Container>*/}
                {/*        <Logo />*/}
                {/*    </Container>*/}
                {/*</Box>*/}
                {/*<Box textAlign="center">*/}
                {/*    <Typography*/}
                {/*        color="#3D4852"*/}
                {/*        variant="h1"*/}
                {/*        fontWeight="bold"*/}
                {/*        whiteSpace="nowrap"*/}
                {/*        fontSize="50px"*/}
                {/*        lineHeight={1}*/}
                {/*    >*/}
                {/*        Stop scrambling for links.*/}
                {/*    </Typography>*/}
                {/*    <StyledTypography*/}
                {/*        color="#3D4852"*/}
                {/*        variant="h1"*/}
                {/*        fontWeight="bold"*/}
                {/*        whiteSpace="nowrap"*/}
                {/*        marginBottom="15px"*/}
                {/*        fontSize="36px"*/}
                {/*    >*/}
                {/*        Retrieve <strong color="white">your</strong> memory, <strong color="primary">your</strong> way.*/}
                {/*    </StyledTypography>*/}
                {/*    <Button*/}
                {/*        onClick={() => {navigate("/sign-up")}}*/}
                {/*        variant="contained"*/}
                {/*        size="large"*/}
                {/*        // color="white"*/}
                {/*        sx={{*/}
                {/*            borderRadius: "20px",*/}
                {/*            textTransform: "none",*/}
                {/*            fontWeight: "bold"*/}
                {/*        }}*/}
                {/*    >Try for free</Button>*/}
                {/*    <Box*/}
                {/*        marginTop="10px"*/}
                {/*        display="flex"*/}
                {/*        alignItems="center"*/}
                {/*        justifyContent="center"*/}
                {/*    >*/}
                {/*        <ChromeIcon />*/}
                {/*        <Typography*/}
                {/*            marginLeft="4px"*/}
                {/*            fontSize="14px"*/}
                {/*            color="#3D4852"*/}
                {/*            fontWeight="bold"*/}
                {/*        >*/}
                {/*            Available as Chrome extension*/}
                {/*        </Typography>*/}
                {/*    </Box>*/}
                {/*</Box>*/}
                {/*<Box*/}
                {/*    display="flex"*/}
                {/*    paddingX="20px"*/}
                {/*    paddingTop="15px"*/}
                {/*    justifyContent="center"*/}
                {/*>*/}
                {/*    <Box*/}
                {/*        sx={{*/}
                {/*            marginRight: "10px",*/}
                {/*            [theme.breakpoints.up('lg')]: {*/}
                {/*                marginRight: "40px", // Padding value for screens equal to or larger than 'lg' breakpoint*/}
                {/*            },*/}
                {/*        }}*/}
                {/*        // marginRight="15px"*/}
                {/*    >*/}
                {/*        <Paper*/}
                {/*            elevation={6}*/}
                {/*            sx={{*/}
                {/*                overflow: "hidden",*/}
                {/*                maxHeight: "427px",*/}
                {/*                marginTop: "10px"*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            <img src="/hint_example.png"*/}
                {/*                 style={{*/}
                {/*                     maxWidth: "100%",*/}
                {/*                     height: "auto",*/}
                {/*                     marginBottom: "-5px"*/}
                {/*                 }} />*/}
                {/*        </Paper>*/}
                {/*    </Box>*/}
                {/*    <Box>*/}
                {/*        <img src="/search_example.png" style={{maxWidth: "100%", height: "auto"}} />*/}
                {/*    </Box>*/}
                {/*</Box>*/}
                {/*<Box*/}
                {/*    paddingTop="30px"*/}
                {/*    textAlign="center"*/}
                {/*>*/}
                {/*    <Typography*/}
                {/*        variant="h6"*/}
                {/*        fontSize="24px"*/}
                {/*        fontWeight="bold"*/}
                {/*        color="#3D4852"*/}
                {/*    >*/}
                {/*        Search your web pages by the <Typewriter*/}
                {/*        options={{*/}
                {/*            loop: true,*/}
                {/*            wrapperClassName: "typewriter-text"*/}
                {/*        }}*/}
                {/*        onInit={(typewriter) => {*/}
                {/*            typewriter.typeString('meaning...')*/}
                {/*                .pauseFor(3000)*/}
                {/*                .deleteAll()*/}
                {/*                .callFunction(() => {*/}
                {/*                    console.log('All strings were deleted');*/}
                {/*                })*/}
                {/*                .typeString("purpose...")*/}
                {/*                .pauseFor(3000)*/}
                {/*                .start();*/}
                {/*        }}*/}
                {/*    />*/}
                {/*    </Typography>*/}
                {/*</Box>*/}
                {/*<Box*/}
                {/*    marginTop="30px"*/}
                {/*    paddingY="50px"*/}
                {/*    sx={{backgroundColor: "#faf6eb"}}*/}
                {/*>*/}
                {/*    <Container>*/}
                {/*        <Typography*/}
                {/*            color="#3D4852"*/}
                {/*            fontWeight="bold"*/}
                {/*            fontSize="16px"*/}
                {/*        >Features</Typography>*/}
                {/*        <Typography*/}
                {/*            variant="h1"*/}
                {/*            fontSize="36px"*/}
                {/*            fontWeight="bold"*/}
                {/*            sx={{*/}
                {/*                background: "linear-gradient(to bottom right, #FFCA4B 20%, #DE9D00 80%);",*/}
                {/*                WebkitBackgroundClip: "text",*/}
                {/*                WebkitTextFillColor: "transparent"*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            Beyond Keyword Matching*/}
                {/*        </Typography>*/}
                {/*        <Box*/}
                {/*            marginY="20px"*/}
                {/*            display="flex"*/}
                {/*            flexDirection="column"*/}
                {/*            width="50%"*/}
                {/*        >*/}
                {/*            <Box paddingRight="40px">*/}
                {/*                <Typography*/}
                {/*                    color="#3D4852"*/}
                {/*                    fontSize="24px"*/}
                {/*                    fontWeight="bold"*/}
                {/*                >*/}
                {/*                    Automatic Summarization*/}
                {/*                </Typography>*/}
                {/*                <Typography*/}
                {/*                    variant="body1"*/}
                {/*                    color="#3D4852"*/}
                {/*                    fontSize="16px"*/}
                {/*                >*/}
                {/*                    When you save a web page, we save a searchable summary for you, making it even easier for you to find it.*/}
                {/*                </Typography>*/}
                {/*            </Box>*/}
                {/*        </Box>*/}
                {/*        <Box*/}
                {/*            marginY="20px"*/}
                {/*            display="flex"*/}
                {/*            flexDirection="column"*/}
                {/*            alignItems="flex-end"*/}
                {/*        >*/}
                {/*            <Box width="50%">*/}
                {/*                <Box paddingLeft="40px">*/}
                {/*                    <Typography*/}
                {/*                        color="#3D4852"*/}
                {/*                        fontSize="24px"*/}
                {/*                        fontWeight="bold"*/}
                {/*                    >*/}
                {/*                        Retrieval Hints*/}
                {/*                    </Typography>*/}
                {/*                    <Typography*/}
                {/*                        variant="body1"*/}
                {/*                        color="#3D4852"*/}
                {/*                        fontSize="16px"*/}
                {/*                    >*/}
                {/*                        In cases you anticipate to use a web page in a certain way, retrieval hints make it searchable by how you planned to use it.*/}
                {/*                    </Typography>*/}
                {/*                </Box>*/}
                {/*            </Box>*/}
                {/*        </Box>*/}
                {/*        <Box*/}
                {/*            marginY="20px"*/}
                {/*            display="flex"*/}
                {/*            flexDirection="column"*/}
                {/*            width="50%"*/}
                {/*        >*/}
                {/*            <Box paddingRight="40px">*/}
                {/*                <Typography*/}
                {/*                    color="#3D4852"*/}
                {/*                    fontSize="24px"*/}
                {/*                    fontWeight="bold"*/}
                {/*                >*/}
                {/*                    Web Page Snippets*/}
                {/*                </Typography>*/}
                {/*                <Typography*/}
                {/*                    variant="body1"*/}
                {/*                    color="#3D4852"*/}
                {/*                    fontSize="16px"*/}
                {/*                >*/}
                {/*                    For when you only remember parts of a web page, search will also surface web pages when relevant snippets are found.*/}
                {/*                </Typography>*/}
                {/*            </Box>*/}
                {/*        </Box>*/}
                {/*    </Container>*/}
                {/*</Box>*/}
                hello :)
            </Box>
        </ThemeProvider>
    )
}

export default LandingPage;