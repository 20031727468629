import React from "react";
import './App.css';
import {isSessionAuthenticated} from "./auth";
import {createBrowserRouter, RouterProvider, redirect} from "react-router-dom";
import App from "./App";
import LandingPage from "./LandingPage";
import SignInPage from "./SignInPage";
import {ThemeProvider} from "@mui/material";
import theme from "./theme";
import AuthClient from "./auth_client";
import AuthSuccessPage from "./AuthSuccessPage";
import SignUpPage from "./SignUpPage";

function Root() {
    const requireAuth = async () => {
        if (!await isSessionAuthenticated()) {
            return redirect("/sign-in");
        }
        return null;
    };

    const profileLoader = async () => {
        try {
            return await AuthClient.getMyProfile()
        } catch (err) {
            return redirect("/sign-in");
        }
    }

    const router = createBrowserRouter([
        {
            path: "/",
            children: [
                {
                    path: "",
                    element: <LandingPage />,
                },
                {
                    path: "app",
                    element: <App />,
                    loader: requireAuth,
                },
                {
                    path: "sign-in",
                    element: <SignInPage />
                },
                {
                    path: "sign-up",
                    element: <SignUpPage />
                },
                {
                    path: "auth/success",
                    element: <AuthSuccessPage/>,
                    loader: profileLoader
                }
            ]
        },
    ])

    return (
        <ThemeProvider theme={theme}>
            <RouterProvider router={router} />
        </ThemeProvider>
    );
}

export default Root;