import googleIcon from "./google-icon.svg"
import {Box} from "@mui/material";

const GoogleIcon = () => {
    return (
        <Box
            display="flex"
            alignItems="center"
        >
            <img src={googleIcon} style={{maxWidth: "24px", maxHeight: "24px"}} />
        </Box>
    )
}

export default GoogleIcon;