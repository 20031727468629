import {CodeResponse, useGoogleLogin} from "@react-oauth/google";
import {Button} from "@mui/material";
import GoogleIcon from "./GoogleIcon";

type GoogleAuthButtonProps = {
    referrer: "sign-up" | "sign-in"
}

const GoogleAuthButton = (props: GoogleAuthButtonProps) => {
    const login = useGoogleLogin({
        flow: "auth-code",
        ux_mode: "redirect",
        state: JSON.stringify({
            referrer: props.referrer
        }),
        redirect_uri: process.env.REACT_APP_API_URL + "/auth/google",
    })
    console.log(process.env.REACT_APP_API_URL + "/auth/google")

    return (
        <Button
            fullWidth
            variant="outlined"
            color="inherit"
            onClick={login} startIcon={<GoogleIcon />}
            sx={{
                textTransform: "none",
                fontWeight: "bold",
                paddingY: "15px",
                borderRadius: "50px"
            }}
        >
            {props.referrer === "sign-up" ? "Sign up with Google" : "Continue with Google"}
        </Button>
    )
}

export default GoogleAuthButton