import logoSvg from "./logo.svg";
import {Box, Typography} from "@mui/material";

type LogoProps = {
    size?: "medium" | "large"
}

const Logo = (props: LogoProps) => {
    const imgSize = props.size === "large" ? "80" : "60"
    const fontSize = props.size === "large" ? "43px" : "32px"
    const marginTop = props.size === "large" ? "5px" : "3px"

    return (
        <Box display="flex" alignItems="center">
            <img src={logoSvg} width={imgSize} height={imgSize} />
            <Typography
                display="inline"
                variant="h1"
                fontSize={fontSize}
                fontWeight="bold"
                marginTop={marginTop}
                marginLeft="2px"
                color="primary.main"
            >
                Bin
            </Typography>
        </Box>
    )
}

export default Logo;